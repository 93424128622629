<template>
    <div class="order">
        <form style="margin-bottom:10px;">
            <a-row type="flex" justify="start">
                <a-col :span="24">
                    <a-row :gutter="10">
                        <a-col :span="3">
                            <a-input v-model="keyword" placeholder="请输入关键词搜索备注" />
                        </a-col>
                        <a-col :span="3">
                            <a-select
                                :value="search_user_value"
                                show-search
                                placeholder="请输入关键词搜索用户"
                                :show-arrow="true"
                                :filter-option="false"
                                :not-found-content="search_user_fetching ? undefined : null"
                                @search="searchUserSearch"
                                @change="searchUserChange"
                            >
                                <a-select-option key="">所有用户</a-select-option>
                                <a-spin v-if="search_user_fetching" slot="notFoundContent" size="small" />
                                <a-select-option v-for="d in search_user_list" :key="d.id">{{ d.truename+' ('+d.mobile+')' }}</a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="3">
                            <a-select
                                :value="search_branch_value"
                                show-search
                                placeholder="请输入关键词搜索网点"
                                :show-arrow="true"
                                :filter-option="false"
                                :not-found-content="search_branch_fetching ? undefined : null"
                                @search="searchBranchSearch"
                                @change="searchBranchChange"
                            >
                                <a-select-option key="">所有网点</a-select-option>
                                <a-spin v-if="search_branch_fetching" slot="notFoundContent" size="small" />
                                <a-select-option v-for="d in search_branch_list" :key="d.id">{{ d.title+' ('+d.phone+')' }}</a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="3">
                            <a-select
                                :value="search_agent_value"
                                show-search
                                placeholder="请输入关键词搜索代理商"
                                :show-arrow="true"
                                :filter-option="false"
                                :not-found-content="search_agent_fetching ? undefined : null"
                                @search="searchAgentSearch"
                                @change="searchAgentChange"
                            >
                                <a-select-option key="">所有代理商</a-select-option>
                                <a-spin v-if="search_agent_fetching" slot="notFoundContent" size="small" />
                                <a-select-option v-for="d in search_agent_list" :key="d.id">{{ d.truename+' ('+d.mobile+')' }}</a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="3">
                            <a-select v-model="search_type" style="width: 100%" placeholder="请选择电池类型">
                                <a-select-option value="">所有电池类型</a-select-option>
                                <a-select-option v-for="item in type_list" :key="item.id" :value="item.id" :label="item.id">
                                    {{ item.title }}
                                </a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="4">
                            <a-range-picker @change="onChange" />
                        </a-col>
                        <a-col :span="3">
                            <a-button type="primary" @click="get_data()">搜索</a-button>
                            <a-button type="primary" v-if="$Func.check_rank('order_export')" @click="export_data()" style="margin-left:10px;">导出</a-button>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
        </form>
        <div class="tabs">
            <a-menu v-model="current" mode="horizontal" @click="change_tab">
                <a-menu-item :key="0" :value="0">全部</a-menu-item>
                <a-menu-item :key="-2" :value="-2">未处理</a-menu-item>
                <a-menu-item :key="-1" :value="-1">已处理</a-menu-item>
                <a-menu-item :key="1" :value="1">待报价</a-menu-item>
                <a-menu-item :key="2" :value="2">已报价</a-menu-item>
                <a-menu-item :key="3" :value="3">确认交易</a-menu-item>
                <a-menu-item :key="4" :value="4">未交易</a-menu-item>
                <a-menu-item :key="8" :value="8">已打款</a-menu-item>
                <a-menu-item :key="99" :value="99">交易完成</a-menu-item>
            </a-menu>
        </div>
        <div class="total">总单数: <span>{{total}}</span>总金额: <span>{{order_total}}</span></div>
        <a-table :columns="columns" :data-source="tableData" @change="change_filter" :loading="loading" :pagination="page" bordered>
            <template slot="user" slot-scope="text, record">
                {{record.users_truename}}<br />{{record.users_mobile}}
            </template>
            <template slot="pic" slot-scope="text, record">
                <div v-if="record.pic" class="pics">
                    <a-badge :count="record.pic.length"><a href="javascript:void(0);" @click="show_pic(record.pic)"><img :src="record.pic[0].url" /></a></a-badge>
                </div>
            </template>
            <template slot="agent" slot-scope="text, record">
                <div v-if="record.agent">{{record.agent.truename}}<br /> {{record.agent.mobile}}</div>
                <div v-else> </div>
            </template>
            <template slot="branch" slot-scope="text, record">
                {{record.branch_title}}<br />{{record.branch_contact}}<br />{{record.branch_phone}}<br />{{record.branch_address}}
            </template>
            <template slot="order_info" slot-scope="text, record">
                电池类型：{{record.type_title}}<br />
                车辆里程：{{record.mileage}}<br />
                总数量：{{record.num}}<br />
                总货重：{{record.weight}}<br />
                总容量：{{record.capacity}}
            </template>
            <template slot="operation" slot-scope="text, record">
                <a @click="()=>{process(record)}" v-if="$Func.check_rank('order_process')">处理</a>
                <a-divider type="vertical" />
                <a @click="()=>{update_data(record)}" v-if="$Func.check_rank('order_edit')">编辑</a>
                <a-divider type="vertical" />
                <a @click="()=>{delete_data(record)}" v-if="$Func.check_rank('order_del')">删除</a>
            </template>
        </a-table>
        <a-drawer
          title="修改询价订单"
          :width="720"
          :maskStyle="{backgroundColor: '#00000010'}"
          :visible="update_model"
          :body-style="{ paddingBottom: '80px' }"
          @close="update_model=false"
          v-if="$Func.check_rank('order_edit')"
        >
            <a-form-model :model="order_update" :rules="rules" layout="vertical" ref="update_form" @submit="save_data">
                <a-divider orientation="left">
                    用户信息
                </a-divider>
                <a-row :gutter="16">
                    <a-col :span="8">
                        <a-form-model-item label="用户">
                            <a-select
                                show-search
                                :value="update_user_value"
                                placeholder="请选择用户"
                                :show-arrow="true"
                                :filter-option="false"
                                :not-found-content="update_user_fetching ? undefined : null"
                                @search="updateUserSearch"
                                @change="updateUserChange"
                            >
                                <a-spin v-if="update_user_fetching" slot="notFoundContent" size="small" />
                                <a-select-option v-for="d in update_user_list" :key="d.id">{{ d.truename+' ('+d.mobile+')' }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item label="网点">
                            <a-select
                                show-search
                                :value="update_branch_value"
                                placeholder="请选择网点"
                                :show-arrow="true"
                                :filter-option="false"
                                :not-found-content="update_branch_fetching ? undefined : null"
                                @search="updateBranchSearch"
                                @change="updateBranchChange"
                            >
                                <a-spin v-if="update_branch_fetching" slot="notFoundContent" size="small" />
                                <a-select-option v-for="d in update_branch_list" :key="d.id">{{ d.title }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item label="代理商">
                            <a-select
                                show-search
                                :value="update_agent_value"
                                placeholder="请选择网点"
                                :show-arrow="true"
                                :filter-option="false"
                                disabled
                            >
                                <a-spin v-if="update_agent_fetching" slot="notFoundContent" size="small" />
                                <a-select-option v-for="d in update_agent_list" :key="d.id">{{ d.truename+' ('+d.mobile+')' }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-divider orientation="left">
                    电池类型
                </a-divider>
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-model-item ref="type" prop="type" label="电池类型">
                            <a-select v-model="order_update.type" style="width: 100%" placeholder="请选择电池类型">
                                <a-select-option v-for="item in type_list" :key="item.id" :value="item.id" :label="item.id">
                                    {{ item.title }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="6">
                        <a-form-model-item ref="mileage" prop="mileage" label="车辆里程">
                            <a-input-number style="width:100%;" v-model="order_update.mileage" placeholder="请输入车辆里程" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-model-item ref="num" prop="num" label="总数量">
                            <a-input-number style="width:100%;" v-model="order_update.num" placeholder="请输入总数量" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-model-item ref="weight" prop="weight" label="总货重">
                            <a-input-number style="width:100%;" v-model="order_update.weight" placeholder="请输入总货重" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-model-item ref="capacity" prop="capacity" label="总容量">
                            <a-input-number style="width:100%;" v-model="order_update.capacity" placeholder="请输入总容量" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item ref="pic" prop="pic" label="图片">
                            <a-upload name="imgFile" :multiple="true" list-type="picture" class="upload-list-inline" :action="base_url+'?action=api.ed_upload&adm_id='+$store.state.info.id+'&token='+$store.state.info.token" :file-list="order_update.pic" @change="picUpdateChange">
                                <a-button> <a-icon type="upload" /> 上传图片 </a-button>
                            </a-upload>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item ref="note" prop="note" label="备注信息">
                            <a-textarea v-model="order_update.note" rows="4" placeholder="请输入备注信息" />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-divider orientation="left">
                    报价
                </a-divider>
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-model-item ref="status" prop="status" label="状态">
                            <a-select v-model="order_update.status" style="width: 100%" placeholder="请选择状态">
                                <a-select-option v-for="item in status_list" :key="item.id" :value="item.id" :label="item.id">
                                    {{ item.title }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item ref="price" prop="price" label="价格">
                            <a-input v-model="order_update.price" placeholder="请输入价格" style="width:100%;" />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <div :style="{position: 'absolute',right: 0,bottom: 0,width: '100%',borderTop: '1px solid #e9e9e9',padding: '10px 16px',background: '#fff',textAlign: 'right',zIndex: 1}">
                    <a-button :style="{ marginRight: '8px' }" @click="update_model=false">取消</a-button>
                    <a-button type="primary" html-type="submit">确定</a-button>
                </div>
            </a-form-model>
        </a-drawer>
        <a-drawer
          title="更改状态"
          :width="720"
          :maskStyle="{backgroundColor: '#00000010'}"
          :visible="process_model"
          :body-style="{ paddingBottom: '80px' }"
          @close="process_model=false"
          v-if="$Func.check_rank('order_process')"
        >
            <a-form-model :model="order_process" :rules="process_rules" layout="vertical" ref="process_form" @submit="process_data">
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-model-item ref="status" prop="status" label="状态">
                            <a-select v-model="order_process.status" placeholder="请选择状态">
                                <a-select-option v-for="item in status_list" :key="item.id" :value="item.id" :label="item.id">
                                    {{ item.title }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item ref="price" prop="price" label="价格">
                            <a-input v-model="order_process.price" placeholder="请输入价格" style="width:100%;" />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <div :style="{position: 'absolute',right: 0,bottom: 0,width: '100%',borderTop: '1px solid #e9e9e9',padding: '10px 16px',background: '#fff',textAlign: 'right',zIndex: 1}">
                    <a-button :style="{ marginRight: '8px' }" @click="process_model=false">取消</a-button>
                    <a-button type="primary" html-type="submit">确定</a-button>
                </div>
            </a-form-model>
        </a-drawer>
        <Pics />
    </div>
</template>

<script>
    import Func from '../common/func'
    import Pics from '../components/Pics';
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 80,
        },
        {
            title: '用户',
            dataIndex: 'users_truename',
            key: 'users_truename',
            scopedSlots: { customRender: 'user' },
            width: 150,
        },
        {
            title: '网点',
            dataIndex: 'branch_title',
            key: 'branch_title',
            scopedSlots: { customRender: 'branch' },
            width: 200,
        },
        {
            title: '代理商',
            dataIndex: 'agent',
            key: 'agent',
            scopedSlots: { customRender: 'agent' },
            width: 150,
        },
        {
            title: '电池信息',
            dataIndex: 'type_title',
            key: 'type_title',
            width: 170,
            scopedSlots: { customRender: 'order_info' },
        },
        {
            title: '图片',
            dataIndex: 'pic',
            key: 'pic',
            width: 130,
            scopedSlots: { customRender: 'pic' },
        },
        {
            title: '备注',
            dataIndex: 'note',
            key: 'note',
            width: 130
        },
        {
            title: '报价',
            dataIndex: 'price',
            key: 'price',
            ellipsis: true,
            width: 120,
        },
        {
            title: '处理',
            dataIndex: 'process_str',
            key: 'process_str',
            ellipsis: true,
            width: 90,
        },
        {
            title: '状态',
            dataIndex: 'status_str',
            key: 'status_str',
            ellipsis: true,
            width: 90,
        },
        {
            title: '状态时间',
            dataIndex: 'status_time',
            key: 'status_time',
            ellipsis: true,
            width: 180,
        },
        {
            title: '创建时间',
            dataIndex: 'created',
            key: 'created',
            ellipsis: true,
            width: 180,
        },
        {
            title: '操作',
            dataIndex: 'id',
            key:'operation',
            width: 160,
            scopedSlots: { customRender: 'operation' },
        },
    ];
    let search_user_timeout;
    let search_branch_timeout;
    let search_agent_timeout;
    let update_user_timeout;
    let update_branch_timeout;
    let update_agent_timeout;
    export default {
        name: 'order',
        components:{Pics},
        data(){
            return {
                btypeOptions:[],
                schoolOptions:[],
                current: [0],
                status:0,
                columns,
                form: this.$form.createForm(this),
                keyword:'',
                range:[],
                tableData:[],
                type_list:[],
                search_user_fetching: false,
                search_user_value:undefined,
                search_user_list:[],

                search_branch_fetching: false,
                search_branch_value:undefined,
                search_branch_list:[],

                search_agent_fetching: false,
                search_agent_value:undefined,
                search_agent_list:[],

                search_type:'',

                //用户信息修改 begin
                update_user_fetching: false,
                update_user_value:'',
                update_user_list:[],

                update_branch_fetching: false,
                update_branch_value:'',
                update_branch_list:[],

                update_agent_fetching: false,
                update_agent_value:'',
                update_agent_list:[],
                //用户信息修改 end
                total:0,
                order_total:0,

                status_list:[
                    {
                        id:1,
                        title:'待报价'
                    },
                    {
                        id:2,
                        title:'已报价'
                    },
                    {
                        id:3,
                        title:'确认交易'
                    },
                    {
                        id:4,
                        title:'未交易'
                    },
                    {
                        id:8,
                        title:'已打款'
                    },
                    {
                        id:99,
                        title:'交易完成'
                    }
                ],
                loading:false,
                pic_loading:false,
                update_model:false,
                process_model:false,
                pic:'',
                base_url:Func.api_url,
                pic_url:Func.pic_url,
                order_update:{
                    id:0,
                    uid:'',
                    branch_id:'',
                    agent_id:'',
                    type:1,
                    mileage:'',
                    num:'',
                    weight:'',
                    capacity:'1',
                    pic:[],
                    note:'',
                    status:1,
                    price:''
                },
                order_process:{
                    id:0,
                    status:1,
                    price:''
                },
                rules: {
                    type:[{ required: true, message: '请选择电池类型', trigger: 'change' }],
                    mileage:[{ required: true, message: '请输入车辆里程', trigger: 'blur' }],
                    num:[{ required: true, message: '请输入总数量', trigger: 'blur' }],
                    weight:[{ required: true, message: '请输入总货重', trigger: 'blur' }],
                    capacity:[{ required: true, message: '请输入总容量', trigger: 'blur' }],
                    status:[{ required: true, message: '请选择状态', trigger: 'change' }]
                },
                process_rules: {
                    status:[{ required: true, message: '请选择状态', trigger: 'change' }]
                },
                page:{
                    current:1,
                    total:0,
                    pageSize:10
                }
            }
        },
        created() {
            var status=parseInt(this.$route.query.status);
            if(status){
                this.status=status;
                this.current=[status];
            }
            
            this.get_type();
            this.get_data();
        },
        methods: {
            show_pic(data){
                this.$PubSub.publish('show_pics',data);
            },
            onChange(date, dateString) {
                this.range=dateString;
            },
            //指定用户 begin
            searchUserSearch(keyword) {
                if (search_user_timeout) {
                    clearTimeout(search_user_timeout);
                    search_user_timeout = null;
                }
                search_user_timeout = setTimeout(()=>{
                    Func.url_get({
                        action:'users.search',
                        keyword
                    },(res) => {
                        this.search_user_list = res.info.list;
                    });
                }, 300);
            },
            searchUserChange(value) {
                this.search_user_value=value;
            },

            searchBranchSearch(keyword) {
                if (search_branch_timeout) {
                    clearTimeout(search_branch_timeout);
                    search_branch_timeout = null;
                }
                search_branch_timeout = setTimeout(()=>{
                    Func.url_get({
                        action:'branch.search',
                        keyword
                    },(res) => {
                        this.search_branch_list = res.info.list;
                    });
                }, 300);
            },
            searchBranchChange(value) {
                this.search_branch_value=value;
            },

            searchAgentSearch(keyword) {
                if (search_agent_timeout) {
                    clearTimeout(search_agent_timeout);
                    search_agent_timeout = null;
                }
                search_agent_timeout = setTimeout(()=>{
                    Func.url_get({
                        action:'agent.search',
                        keyword
                    },(res) => {
                        this.search_agent_list = res.info.list;
                    });
                }, 300);
            },
            searchAgentChange(value) {
                this.search_agent_value=value;
            },

            //用户信息修改 begin
            updateUserSearch(keyword) {
                if (update_user_timeout) {
                    clearTimeout(update_user_timeout);
                    update_user_timeout = null;
                }
                update_user_timeout = setTimeout(()=>{
                    Func.url_get({
                        action:'users.search',
                        keyword
                    },(res) => {
                        this.update_user_list = res.info.list;
                    });
                }, 300);
            },
            updateUserChange(value) {
                this.order_update.uid = value;
                this.update_user_value=value;
            },

            updateBranchSearch(keyword) {
                if (update_branch_timeout) {
                    clearTimeout(update_branch_timeout);
                    update_branch_timeout = null;
                }
                update_branch_timeout = setTimeout(()=>{
                    Func.url_get({
                        action:'branch.search',
                        keyword
                    },(res) => {
                        this.update_branch_list = res.info.list;
                    });
                }, 300);
            },
            updateBranchChange(value) {
                this.order_update.branch_id = value;
                this.update_branch_value=value;
            },

            updateAgentSearch(keyword) {
                if (update_agent_timeout) {
                    clearTimeout(update_agent_timeout);
                    update_agent_timeout = null;
                }
                update_agent_timeout = setTimeout(()=>{
                    Func.url_get({
                        action:'agent.search',
                        keyword
                    },(res) => {
                        this.update_agent_list = res.info.list;
                    });
                }, 300);
            },
            updateAgentChange(value) {
                this.order_update.agent_id = value;
                this.update_agent_value=value;
            },
            //用户信息修改 end

            picUpdateChange(info) {
                let fileList = [...info.fileList];

                // 2. read from response and show file link
                fileList = fileList.map(file => {
                    if (file.response) {
                        // Component will show file.url as link
                        file.url = this.pic_url+file.response.url;
                    }

                    return file;
                });

                console.log(fileList);
                this.order_update.pic = fileList;
            },
            beforeUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 200;
                if (!isLt2M) {
                    this.$message.error('请上传200M以内文件!');
                }
                return isLt2M;
            },
            get_type() {
                Func.url_get({
                    action:'type.list_all'
                },(res) => {
                    this.type_list = res.info;
                });
            },
            change_tab(e){
                this.status=e.key;
                this.get_data(1);
            },
            change_filter(pagination){
                this.get_data(pagination.current);
            },
            get_data(page,tip) {
                var keyword = this.keyword;
                if (!page) {
                    page = this.page.current||1;
                }

                if(!tip){
                    this.loading = true
                }

                Func.url_get({
                    action:'order',
                    keyword: keyword,
                    range:this.range,
                    uid:this.search_user_value||'',
                    branch_id:this.search_branch_value||'',
                    agent_id:this.search_agent_value||'',
                    type:this.search_type,
                    status:this.status,
                    page: page
                },(res) => {
                    this.tableData = res.info.list;
                    var pagination={
                        pageSize:10,
                        total:res.info.foot.total,
                        current:parseInt(res.info.foot.current)
                    }
                    this.total=res.info.total;
                    this.order_total=res.info.order_total;
                    this.page=pagination;
                    this.loading = false
                });
            },
            export_data() {
                var url=this.base_url+'?action=order.export&keyword='+this.keyword+'&range='+this.range+'&uid='+(this.search_user_value||'')+'&branch_id='+(this.search_branch_value||'')+'&agent_id='+(this.search_agent_value||'')+'&type='+this.search_type+'&status='+this.status+'&adm_id='+this.$store.state.info.id+'&token='+this.$store.state.info.token;

                window.open(url);
                return;
            },
            save_data(e){
                e.preventDefault();
                this.$refs.update_form.validate(valid => {
                    if (valid) {
                        var pic=this.order_update.pic;
                        var pics=pic.map(file=>{
                            return file.url;
                        })
                        Func.url_post({
                            action:'order.save_data',
                            ...this.order_update,
                            pic:pics
                        },(res) => {
                            if(res.error===0){
                                this.$message.success(res.info);
                                this.update_model=false;
                                this.get_data(1,true);
                            }else{
                                this.$message.error(res.info);
                            }
                        });
                    }else{
                        return false;
                    }
                });
            },
            process(res){
                const item=JSON.parse(JSON.stringify(res));
                Func.url_get({
                    action:'order.set_process',
                    id:item.id
                },(res) => {
                    if(res.error===0){
                        this.order_process=item;
                        this.process_model=true;
                    }else{
                        this.$message.error(res.info);
                    }
                });
            },
            process_data(e){
                e.preventDefault();
                this.$refs.process_form.validate(valid => {
                    if (valid) {
                        Func.url_post({
                            action:'order.process',
                            ...this.order_process
                        },(res) => {
                            if(res.error===0){
                                this.$message.success(res.info);
                                this.process_model=false;
                                this.get_data(1,true);
                            }else{
                                this.$message.error(res.info);
                            }
                        });
                    }else{
                        return false;
                    }
                });
            },
            update_data(res){
                const item=JSON.parse(JSON.stringify(res));
                Func.url_get({
                    action:'users.search_one',
                    keyword:item.uid
                },(res) => {
                    this.update_user_list = res.info.list;
                });

                Func.url_get({
                    action:'branch.search_one',
                    keyword:item.branch_id
                },(res) => {
                    this.update_branch_list = res.info.list;
                });

                Func.url_get({
                    action:'agent.search_one',
                    keyword:item.agent_id
                },(res) => {
                    this.update_agent_list = res.info.list;
                });

                this.update_user_value=item.uid;
                this.update_branch_value=item.branch_id;
                this.update_agent_value=item.agent_id;

                this.order_update=item;

                this.update_model=true;
            },
            delete_data(item){
                if(confirm('确定要删除吗')==true){
                    Func.url_get({
                        action:'order.del_data',
                        id:item.id
                    },(res) => {
                        if(res.error===0){
                            this.$message.success(res.info);
                            this.get_data(1,true);
                        }else{
                            this.$message.error(res.info);
                        }
                    });
                }
            },
            goto_url(url,id){
                this.$router.push({
                    path:'/order/'+url,
                    params:{
                        id:id
                    }
                });
            }
        }
    }
</script>
<style scoped>
.ant-drawer-left.ant-drawer-open, .ant-drawer-right.ant-drawer-open,.ant-drawer{
      transition: transform 0.3s linear;
}
.modal-body{
    max-height: 700px;
    overflow: auto;
}
.ant-upload img{
    max-width:100%;
    max-height:100%;
}
.pics img{
    max-width: 100px;
    max-height: 100px;
}
.tabs{
    margin:10px 0;
}
.total{
    padding-bottom: 10px;
    font-size: 18px;
}
.total span{
    padding-right: 30px;
    color: red;
    font-size: 20px;
    font-weight: bolder;
}
/* tile uploaded pictures */
.upload-list-inline >>> .ant-upload-list-item {
  float: left;
  width: 90px;
  margin-right: 8px;
}
.upload-list-inline >>> .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline >>> .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}
</style>