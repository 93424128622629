<template>
<div class="pic_model" v-show="show">
  <div class="close"><a href="javascript:void(0);" @click="show=false"><a-icon type="close-circle" /></a></div>
  <a-carousel arrows>
    <div
      slot="prevArrow"
      class="custom-slick-arrow"
      style="left:10px;zIndex:1"
    >
      <a-icon type="left-circle" />
    </div>
    <div slot="nextArrow" class="custom-slick-arrow" style="right:10px">
      <a-icon type="right-circle" />
    </div>
    <div v-for="item in pics" :key="item.uid"><img :src="item.url" /></div>
  </a-carousel>
</div>
</template>

<script>
export default {
  name: 'Pics',
  data(){
    return {
      show:false,
      pics:[]
    }
  },
  mounted(){
    this.$PubSub.subscribe('show_pics',(msg,data)=>{
      this.pics=data;
      setTimeout(()=>{
        this.show=true;
      },200);
    });
  }
}
</script>
<style scoped>
  .pic_model{
    background: rgba(0,0,0,0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  .close{
    position: absolute;
    width: 100%;
    bottom: 100px;
    text-align: center;
  }
  .close a{
    font-size: 40px;
    color: #fff;
    opacity: 0.4;
  }
  .close:hover a{
    opacity: 0.5;
    color: #fff;
  }
  .ant-carousel{
    width: 1000px;
    max-height: 500px;
    overflow: hidden;
  }
  /* For demo */
  .slick-slide {
    text-align: center;
    line-height: 800px;
    background: #364d79;
    overflow: hidden;
  }

  .custom-slick-arrow {
    width: 25px;
    height: 25px;
    font-size: 25px;
    color: #fff;
    background-color: rgba(31, 45, 61, 0.11);
    opacity: 0.3;
  }
  .custom-slick-arrow:before {
    display: none;
  }
  .custom-slick-arrow:hover {
    opacity: 0.5;
    color: #fff;
  }

  .slick-slide img {
    max-height: 500px;
    max-width: 1000px;
    margin: 0 auto;
  }
</style>
